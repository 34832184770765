<template>
  <div class="gg-container" style="padding: 10px 20px">
    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
      <el-tab-pane label="机构对比" name="first">
        <div class="search-container">
          <div class="search-container-fn-input">
            <label>月份：</label>
            <el-date-picker
              v-model="month"
              type="month"
              size="small"
              clearable
              placeholder="月份"
              @change="getListSecond('restPage')"
            >
            </el-date-picker>
          </div>

          <el-button
            size="mini"
            type="primary"
            style="
              padding: 6px 7px 7px 8px;
              background-color: #2362fb;
              border-color: #2362fb;
              margin-left: auto;
            "
            @click="handleExportOrganizationContrast()"
          >
            <i class="iconfont icon-daoru" style="font-size: 13px" />
            <!-- <span style="margin-left: 5px; font-size: 12px">导出</span> -->
          </el-button>
        </div>

        <el-table
          border
          v-loading="listLoading"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="tableDataSecond"
          style="width: 100%"
        >
          <el-table-column
            fixed
            label="机构名称"
            prop="agent_name"
            align="center"
            width="150"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="small"
                @click="docSuperviseCompareVisible(row)"
              >
                {{ row.agent_name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="上传监管问诊数" align="center">
            <el-table-column
              prop="supervise_diag_pic_count"
              label="图文"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_diag_video_count"
              label="视频"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="上传监管电子病历数" align="center">
            <el-table-column
              prop="supervise_ask_emr_count"
              label="咨询类问诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_diag_emr_count"
              label="诊疗类问诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="supervise_pres_count"
            label="上传监管处方数"
            align="center"
          >
            <el-table-column
              prop="supervise_wm_pres_count"
              label="成药处方"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_ts_pres_count"
              label="中药处方"
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getListSecond"
        />
      </el-tab-pane>

      <el-tab-pane label="机构详情" name="second">
        <div class="search-container">
          <div class="search-container-fn-input">
            <label>年份：</label>
            <el-date-picker
              v-model="searchParamsFirst.year"
              type="year"
              size="small"
              clearable
              placeholder="选择年"
              value-format="yyyy"
              @change="getList('restPage')"
            >
            </el-date-picker>
          </div>

          <div class="search-container-fn-input">
            <label>机构：</label>
            <el-select
              v-model="searchParamsFirst.agent_id"
              placeholder="请选择"
              size="small"
              clearable
              @change="getList('agent')"
            >
              <template slot="prefix">
                <i
                  class="el-icon-search"
                  style="width: 25px; line-height: 32px"
                ></i>
              </template>
              <el-option
                v-for="item in organizationList"
                :key="item.id"
                :label="item.merchant_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div class="search-container-fn-input">
            <label>医生：</label>
            <el-select
              v-model="searchParamsFirst.doctor_id"
              placeholder="请选择"
              size="small"
              clearable
              @change="getList('restPage')"
            >
              <template slot="prefix">
                <i
                  class="el-icon-search"
                  style="width: 25px; line-height: 32px"
                ></i>
              </template>
              <el-option
                v-for="item in docList"
                :key="item.id"
                :label="item.doctor_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <el-button
            size="mini"
            type="primary"
            style="
              padding: 6px 7px 7px 8px;
              background-color: #2362fb;
              border-color: #2362fb;
              margin-left: auto;
            "
            @click="handleExportOrganizationDetail()"
          >
            <i class="iconfont icon-daoru" style="font-size: 13px" />
            <!-- <span style="margin-left: 5px; font-size: 12px">导出</span> -->
          </el-button>
        </div>

        <el-table
          border
          v-loading="listLoading"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="tableDataFirst"
          style="width: 100%"
        >
          <el-table-column label="月份" prop="month" align="center" width="80">
          </el-table-column>
           <el-table-column label="上传监管问诊数" align="center">
            <el-table-column
              prop="supervise_diag_pic_count"
              label="图文"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_diag_video_count"
              label="视频"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="上传监管电子病历数" align="center">
            <el-table-column
              prop="supervise_ask_emr_count"
              label="咨询类问诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_diag_emr_count"
              label="诊疗类问诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="supervise_pres_count"
            label="上传监管处方数"
            align="center"
          >
            <el-table-column
              prop="supervise_wm_pres_count"
              label="成药处方"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_ts_pres_count"
              label="中药处方"
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <!--医生监管报表-->
    <el-dialog :title="agentName" :visible.sync="dialogVisible" width="80%">
      <el-table
        :data="docTableData"
        style="width: 100%"
        border
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="医生" align="center">
          <template slot-scope="{ row }">
            {{ row.doctor.doctor_name }}
          </template>
        </el-table-column>

         <el-table-column label="上传监管问诊数" align="center">
            <el-table-column
              prop="supervise_diag_pic_count"
              label="图文"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_diag_video_count"
              label="视频"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="上传监管电子病历数" align="center">
            <el-table-column
              prop="supervise_ask_emr_count"
              label="咨询类问诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_diag_emr_count"
              label="诊疗类问诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="supervise_pres_count"
            label="上传监管处方数"
            align="center"
          >
            <el-table-column
              prop="supervise_wm_pres_count"
              label="成药处方"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="supervise_ts_pres_count"
              label="中药处方"
              align="center"
            >
            </el-table-column>
          </el-table-column>
      </el-table>

      <pagination
        v-show="docTotal > 0"
        :total="docTotal"
        :page.sync="docListQuery.page"
        :limit.sync="docListQuery.page_size"
        @pagination="docSuperviseCompareVisible"
      />
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getSuperviseDetail,
  getSuperviseReportCompare,
  getSuperviseDocCompare,
  getAgentDocList,
} from "@/api/finance";
import { parseTime } from "@/utils";

export default {
  name: "superviseReport",
  components: {
    Pagination,
  },
  created() {
    this.getList();
    this.getListSecond();
    this.getAgentDocList();
    // this._getAdminCommonMerchants();
  },
  data() {
    return {
      docTableData: [],
      dialogVisible: false,
      agentName: "",
      activeName: "first",
      searchParamsFirst: {
        year: this.$moment().format("YYYY"),
        agent_id: 1,
        doctor_id: "",
      },
      month: this.$moment().format("YYYY-MM"),
      searchParamsSecond: {
        month: "",
      },
      organizationList: [],
      listLoading: false,
      tableDataFirst: [],
      tableDataSecond: [],
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      docListQuery: {
        page: 1,
        page_size: 10,
      },
      docTotal: 0,
      docList: [],
    };
  },
  methods: {
    docSuperviseCompareVisible(row) {
      this.agentName = row.agent_name;
      this.docListQuery.month = this.searchParamsSecond.month;
      this.docListQuery.agent_id = row.agent_id;
      getSuperviseDocCompare(this.docListQuery)
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = true;
            this.docTableData = res.data.data;
          }
        })
        .catch((err) => {});
    },
    handleClickTabs(tab, event) {
      console.log(tab, event);
    },
    getList(rest) {
      if (rest == "restPage" || rest == "agent") {
        this.listQuery.page = 1;
        if (rest == "agent") {
          this.searchParamsFirst.doctor_id = "";
          this._getDocList();
        }
      }
      this.listLoading = true;
      let params = { ...this.searchParamsFirst };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getSuperviseDetail(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.tableDataFirst = data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    getListSecond(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      // this.listLoading = true
      let date = new Date(this.month);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      this.searchParamsSecond.month = y + "" + m;
      let params = { ...this.searchParamsSecond };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getSuperviseReportCompare(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            console.log(1111, _data);
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableDataSecond = _data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    getAgentDocList() {
      getAgentDocList().then((response) => {
        if (response.code == 200) {
          this.organizationList = response.data;
          this._getDocList(1);
        }
      });
    },
    _getDocList() {
      let docList = this.organizationList.find(
        (v) => v.id == this.searchParamsFirst.agent_id
      );
      this.docList = docList ? docList.doctor : [];
    },
    // _getAdminCommonMerchants() {
    //   getAdminCommonMerchants().then((response) => {
    //     if (response.code == 200) {
    //       this.organizationList = response.data;
    //     }
    //   });
    // },
    handleExportOrganizationDetail() {},
    handleExportOrganizationContrast() {},
    handleExportPayOrder() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "机构名称",
          "医生名称",
          "银行卡号",
          "姓名",
          "提现金额",
          "排序",
          "提现状态",
          "提现申请时间",
          "结算编号",
        ];
        const filterVal = [
          "address",
          "date",
          "name",
          "name",
          "name",
          "name",
          "name",
          "name",
          "name",
        ];
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "测试导出",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
  },
};
</script>

<style scoped>
</style>
